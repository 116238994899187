import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { Text, BoldText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import { FaTimes } from 'react-icons/fa';
import { createNewDoctor } from '../services';
import { generatePasswordWithPrefix } from '../utils';
import Select from 'react-select';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class VoiceCallSuccessModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showAlert: false,
      alertText: '',
      alertType: 'success',
      doctorName: '',
      doctorQualification: '',
      doctorPassword: '',
      language: '',
      languageOptions: [
        { value: 'Telugu', label: 'Telugu' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'English', label: 'English' },
        { value: 'Tamil', label: 'Tamil' },
        { value: 'Malayalam', label: 'Malayalam' },
        { value: 'Kannada', label: 'Kannada' },
        { value: 'Bengali', label: 'Bengali' },
        { value: 'Marathi', label: 'Marathi' },
        { value: 'Gujarati', label: 'Gujarati' },
        { value: 'Assamese', label: 'Assamese' },
        { value: 'Kashmiri', label: 'Kashmiri' },
        { value: 'Konkani', label: 'Konkani' },
        { value: 'Manipuri', label: 'Manipuri' },
        { value: 'Nepali', label: 'Nepali' },
        { value: 'Oriya', label: 'Oriya' },
        { value: 'Punjabi', label: 'Punjabi' },
        { value: 'Urdu', label: 'Urdu' },
      ],
    };
  }

  handleLanguageChange = (selectedOption) => {
    let languageContainer = '';
    selectedOption.forEach((ele) => {
      languageContainer += ele['value'] + ';;';
    });
    this.setState({ language: languageContainer });
  };

  createDoctor = () => {
    const { doctorPassword, language } = this.state;
    const doctorName = document.querySelector('#doctor-name-input').value;
    const doctorQualification = document.querySelector('#doctor-qualification-input').value;
    const doctorPhone = document.querySelector('#doctor-phone-input').value;
    const registrationNumber = document.querySelector(
      '#registration-number-input',
    ).value;
    const doctorInsurer = document.querySelector('#doctor-type-input').value;
    const doctorSignature =
      document.querySelector('#doctor-signature').files[0];
    const qc = document.querySelector('#doctor-qc').checked;
    const admin = document.querySelector('#doctor-admin').checked ? 1 : '';
    if (doctorSignature && doctorSignature.type.indexOf('image') !== -1) {
      this.setState({
        loading: true,
        showAlert: false,
      });
      createNewDoctor(
        doctorName,
        doctorQualification,
        doctorPhone,
        registrationNumber,
        doctorPassword,
        doctorInsurer,
        doctorSignature,
        qc,
        admin,
        language,
      )
        .then((res) => {
          if (res.message === 'warning') {
            this.setState({
              alertText:
                res.errorMessage ||
                'Something went wrong. Please contact administrator',
              alertType: 'warning',
              showAlert: true,
            });
            return;
          }
          this.setState({
            alertText: 'Doctor account created successfully',
            alertType: 'success',
            showAlert: true,
          });
          return;
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            alertText:
              err.response.errorMessage ||
              'Something went wrong. Please try again later',
            alertType: 'danger',
            showAlert: true,
          });
        })
        .finally(() => {
          this.setState({ loading: false, language: '' });
        });
    } else {
      this.setState({
        alertText: 'Please upload a valid signature',
        alertType: 'danger',
        showAlert: true,
      });
      return;
    }
  };

  getDoctorFirstName(value) {
    if (value.match(/^[Dd]r\.\s.*$/) || value.match(/^[Dd]r\s.*$/)) {
      return value.split(' ')[1];
    } else {
      return value.split(' ')[0];
    }
  }

  setDoctorName = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        doctorName: value,
      });
    } else {
      this.setState({
        doctorName: '',
      });
    }
  };

  setDoctorQualification = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        doctorQualification: value,
      });
    } else {
      this.setState({
        doctorName: '',
      });
    }
  };

  setDoctorPassword = () => {
    const { doctorName } = this.state;
    if (doctorName) {
      const doctorFirstName = this.getDoctorFirstName(doctorName);
      const password = generatePasswordWithPrefix(doctorFirstName);
      this.setState({
        doctorPassword: password,
      });
    } else {
      this.setState({
        doctorPassword: '',
      });
    }
  };

  render() {
    const {
      alertText,
      alertType,
      doctorName,
      doctorQualification,
      doctorPassword,
      showAlert,
      loading,
      languageOptions,
      language,
    } = this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add a new doctor</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`alert collapse ${showAlert ? 'show' : ''} ${alertType === 'success'
                        ? 'alert-success'
                        : alertType === 'warning'
                          ? 'alert-warning'
                          : 'alert-danger'
                        }`}
                      role="alert"
                    >
                      {alertText}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="doctor-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Doctor Name</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-name-input"
                          value={doctorName}
                          onChange={this.setDoctorName}
                          onBlur={this.setDoctorPassword}
                        />
                      </div>
                      <label
                        htmlFor="doctor-qualification-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Doctor Qualification</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-qualification-input"
                        />
                      </div>
                      <label
                        htmlFor="doctor-phone-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Doctor Phone</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-phone-input"
                        />
                      </div>
                      <label
                        htmlFor="registration-number-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Registration Number</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="registration-number-input"
                        />
                      </div>
                      <label
                        htmlFor="doctor-type-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Insurer</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select
                          className="custom-select"
                          id="doctor-type-input"
                        >
                          <option value="max">Max</option>
                          <option value="abhi">ABHI</option>
                          <option value="ipru">IPRU</option>
                          <option value="lic">LIC</option>
                          <option value="aegon">AEGON</option>
                          <option value="reliance">RELIANCE</option>
                          <option value="absli">ABSLI</option>
                          <option value="absli-vv">ABSLI-VV</option>
                          <option value="mchi">MCHI</option>
                          <option value="godigit">Go Digit</option>
                          <option value="tata">TATA AIA</option>
                          <option value="tata">TATA MER</option>
                          <option value="hdfc">HDFC</option>
                          <option value="icicilbd">ICICI LOMBARD</option>
                          <option value="kotak-vfmr">KOTAK VFMR</option>
                          <option value="kotak-vg">KOTAK VG</option>
                        </select>
                      </div>
                      <label
                        htmlFor="doctor-password-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Doctor Password</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-password-input"
                          disabled
                          value={doctorPassword}
                        />
                      </div>
                      <label
                        htmlFor="doctor-language-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Language Selection</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <Select
                          placeholder="Please Select a Language"
                          isMulti
                          onChange={(e) => {
                            this.handleLanguageChange(e);
                          }}
                          className="mt-3 mr-2"
                          options={this.state.languageOptions}
                        />
                      </div>

                      <label
                        htmlFor="doctor-signature"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Doctor Signature</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="file"
                          className="form-control"
                          id="doctor-signature"
                          accept=".jpg,.png,.jpeg"
                        />
                      </div>
                      <label
                        htmlFor="doctor-password-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">QC Doctor</Text>
                      </label>
                      <div className="col-sm-3 mt-3">
                        <input
                          className="form-control"
                          type="checkbox"
                          id="doctor-qc"
                        />
                      </div>

                      <label
                        htmlFor="doctor-password-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Admin</Text>
                      </label>
                      <div className="col-sm-3 mt-3">
                        <input
                          className="form-control"
                          type="checkbox"
                          id="doctor-admin"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <PrimaryButton loading={loading} onTap={this.createDoctor}>
                      <BoldText fontSize="16px" color="white">
                        Create Doctor
                      </BoldText>
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default VoiceCallSuccessModal;
