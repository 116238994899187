import { Button, Table, Form, Input, Tabs } from "antd";
import "antd/dist/antd.css";
import React, { useEffect } from "react";
import { useState } from "react/cjs/react.development";
import { fetchAttemptedSheet, fetchCompleteSheet, fetchDiscrepencySheet, updateCompleteSheet, updateAttemptedSheet, updateDiscrepencySheet } from "../services";
import DashboardHeader from '../components/DashboardHeader';
import styled from 'styled-components';
import visitLogo from '../images/common/visit-logo-white.svg';
function ViewSheets() {
  const [dataSource, setDataSource] = useState([]);
  const [discDataSource, setDiscDataSource] = useState([]);
  const [attemptedDataSource, setAttemptedDataSource] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [form] = Form.useForm();
  const [completeSheet, setCompleteSheet] = useState([]);



  useEffect(() => {
    const data = [];

    //completed cases
    fetchCompleteSheet()
      .then((res) => {

        setDataSource(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // this.setState({loading:false});
        //console.log('finally block kicking in ');
      });
    // setDataSource(data);



    //Attempted cases
    fetchAttemptedSheet()
      .then((res) => {
        console.log('inside ', res);
        setAttemptedDataSource(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // this.setState({loading:false});
        console.log('finally block kicking in ');
      });

    //Discrepency cases
    fetchDiscrepencySheet()
      .then((res) => {
        setDiscDataSource(res);
      }).catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // this.setState({loading:false});
        //console.log('finally block kicking in ');
      });
  }, []);





  const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem;
  background: #8852cc;
  position: relative;
`;

  const LogoContainer = styled.img`
  max-width: 9.8rem;
`;

  const InsurerLogoContainer = styled.img`
  max-width: 9.8rem;
  max-height: 5rem;
  margin-left: 30px;
`;


  const fetchCompleteList = () => {
    fetchCompleteSheet()
      .then((res) => {
        console.log('inside ', res);
        this.setState({
          completeSheet: res,
        })
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // this.setState({loading:false});
        console.log('finally block kicking in ');
      });
  };

  const items = [
    { label: 'Tab 1', key: 'item-1', children: 'Content 1' },
    { label: 'Tab 2', key: 'item-2', children: 'Content 2' },
  ];

  const columns = [
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setEditingRow(record.application_no);
                form.setFieldsValue({
                  updated_date: record.updated_date,
                  reason_for_refferal: record.reason_for_refferal,
                  application_no: record.application_no,
                  case_type: record.case_type,
                  remark: record.remark,
                  attempt1: record.attempt1,
                  first_status: record.first_status,
                  mars_filled: record.mars_filled,
                  mars_comment: record.mars_comment,
                  adversity_flag: record.adversity_flag,
                  remarks_of_adversity: record.remarks_of_adversity
                });
              }}
            >
              Edit
            </Button>
            <Button type="link" htmlType="submit">
              Save
            </Button>
          </>
        );
      },
    },

    {
      title: 'Updated Date',
      dataIndex: 'updated_date'
    },
    {
      title: "Application No",
      dataIndex: 'application_no'

    },
    {
      title: "Case Type",
      dataIndex: "case_type",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='case_type'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Reason For Refferal",
      dataIndex: "reason_for_referral"
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      render: (text, record) => {

        if (editingRow === record.application_no) {
          return (
            <Form.Item name='remark'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Attempt1",
      dataIndex: "attempt1",
      render: (text, record) => {

        if (editingRow === record.application_no) {
          return (
            <Form.Item name='attempt1'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text.slice(0, 20)}</p>;
        }
      }
    },
    {
      title: "How Applied",
      dataIndex: "how_applied_policy"
    },
    {
      title: "Advisors No",
      dataIndex: "advisors_no"
    },
    {
      title: "First Status",
      dataIndex: "first_status",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='first_status'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Asser Name",
      dataIndex: "asser_name"
    },
    {
      title: "Attempt Number",
      dataIndex: "attempt_number"
    },
    {
      title: "1st Call Attempt Date",
      dataIndex: "1st_call_attempt_date"
    },
    {
      title: "1st Call Assign Date",
      dataIndex: "1st_call_assign_date"
    },
    {
      title: "Contactibility",
      dataIndex: "contactibility"
    },
    {
      title: "Mars Filled",
      dataIndex: "mars_filled",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='mars_filled'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Mars Comment",
      dataIndex: "mars_comment",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='mars_comment'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Provider",
      dataIndex: "provider"
    },
    {
      title: "NRI/Non-Nri",
      dataIndex: "nri_or_non-nri"
    },
    {
      title: "Video/Tele",
      dataIndex: "video_or_tele"
    },
    {
      title: "Video Capturing",
      dataIndex: "video_capturing"
    },
    {
      title: "Adversity Flag",
      dataIndex: "adversity_flag",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='adversity_flag'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Remarks of Adversity",
      dataIndex: "remarks_of_adversity",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='remarks_of_adversity'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },


  ];

  //reason_for_referral, tu_status, findings, discrepency_status
  const Discrepencycolumns = [
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setEditingRow(record.application_no);
                form.setFieldsValue({
                  updated_date: record.updated_date,
                  reason_for_referral: record.reason_for_referral,
                  application_no: record.application_no,
                  tu_status: record.tu_status,
                  findings: record.findings,
                  discrepency_status: record.discrepency_status,
                  remark: record.remark,
                  attempt1: record.attempt1,
                  first_status: record.first_status,
                  mars_filled: record.mars_filled,
                  mars_comment: record.mars_comment,
                  adversity_flag: record.adversity_flag,
                  remarks_of_adversity: record.remarks_of_adversity
                });
              }}
            >
              Edit
            </Button>
            <Button type="link" htmlType="submit">
              Save
            </Button>
          </>
        );
      },
    },

    {
      title: 'Updated Date',
      dataIndex: 'updated_date'
    },
    {
      title: "Application No",
      dataIndex: 'application_no'

    },
    {
      title: "Case Type",
      dataIndex: "case_type"
    },
    {
      title: "Reason For Refferal",
      dataIndex: "reason_for_referral",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='reason_for_referral'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },

    {
      title: "Finding",
      dataIndex: "findings",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='findings'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Asser Name",
      dataIndex: "asser_name"
    },
    {
      title: "Attempt Number",
      dataIndex: "attempt_number"

    },
    {
      title: "1st Call Attempt Date",
      dataIndex: "1st_call_attempt_date"
    },
    {
      title: "1st Call Assign Date",
      dataIndex: "1st_call_assign_date",

      style: {
        'text-align': 'center',
      },
    },
    {
      title: "TU Status",
      dataIndex: "tu_status",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='tu_status'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Mars Comment",
      dataIndex: "mars_comment",

    },
    {
      title: "Mars Status",
      dataIndex: "mars_status"
    },
    {
      title: "Provider",
      dataIndex: "provider",
    },
    {
      title: "NRI/Non-Nri",
      dataIndex: "nri_or_non-nri",
    },
    {
      title: "Video/Tele",
      dataIndex: "video_or_tele"
    },
    {
      title: "Video Capturing",
      dataIndex: "video_capturing"
    },
    {
      title: "Discrepency Status",
      dataIndex: "discrepency_status",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='discrepency_status'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    }


  ];


  //attempt1,  remark, first_status
  const AttemptedCol = [
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setEditingRow(record.application_no);
                form.setFieldsValue({
                  updated_date: record.updated_date,
                  reason_for_refferal: record.reason_for_refferal,
                  application_no: record.application_no,
                  case_type: record.case_type,
                  remark: record.remark,
                  attempt1: record.attempt1,
                  first_status: record.first_status,
                  mars_filled: record.mars_filled,
                  mars_comment: record.mars_comment,
                  adversity_flag: record.adversity_flag,
                  remarks_of_adversity: record.remarks_of_adversity
                });
              }}
            >
              Edit
            </Button>
            <Button type="link" htmlType="submit">
              Save
            </Button>
          </>
        );
      },
    },

    {
      title: 'Updated Date',
      dataIndex: 'updated_date'
    },
    {
      title: "Application No",
      dataIndex: 'application_no'

    },
    {
      title: "Case Type",
      dataIndex: "case_type"
    },
    {
      title: "Reason For Referral",
      dataIndex: "reason_for_referral"
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      render: (text, record) => {

        if (editingRow === record.application_no) {
          return (
            <Form.Item name='remark'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Attempt",
      dataIndex: "attempt1",
      render: (text, record) => {

        if (editingRow === record.application_no) {
          return (
            <Form.Item name='attempt1'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "How Have you Applied",
      dataIndex: "how_applied_policy",
      render: (text, record) => {
        return <p>{text.slice(0, 30)}</p>;

      }
    },
    {
      title: "Advisors No",
      dataIndex: "advisors_no"
    },
    {
      title: "First Status",
      dataIndex: "first_status",
      render: (text, record) => {
        if (editingRow === record.application_no) {
          return (
            <Form.Item name='first_status'>
              <Input />
            </Form.Item>
          );
        } else {
          return <p>{text}</p>;
        }
      }
    },
    {
      title: "Asser Name",
      dataIndex: "asser_name"
    },
    {
      title: "No. of Attempt",
      dataIndex: "no_of_attempt"
    },
    {
      title: "Attempt Number",
      dataIndex: "attempt_number",

      style: {
        'text-align': 'center',
      },
    },
    {
      title: "1st Call Attempt Date",
      dataIndex: "1st_call_attempt_date"
    },
    {
      title: "1st Call Assign Date",
      dataIndex: "1st_call_assign_date",

    },
    {
      title: "Contactibility",
      dataIndex: "contactability",

    },
    {
      title: "Mars Filled",
      dataIndex: "mars_filled"
    },
    {
      title: "Provider",
      dataIndex: "provider"
    },
    {
      title: "NRI/Non-Nri",
      dataIndex: "nri_or_non-nri"
    },
    {
      title: "Video/Tele",
      dataIndex: "video_or_tele"
    },
    {
      title: "Video Capturing",
      dataIndex: "video_capturing"
    }


  ];



  const getInsurerLogo = () => {
    const insurer = this.props.insurer;
    if (!insurer) {
      return null;
    }
    switch (insurer) {
      case 'abhi':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/abhi-insurance.jpg';
      case 'max':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/max.png';
      case 'ipru':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/ipru-logo.png';
      case 'aegon':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/aegon-logo.png';
      case 'lic':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/assets/lic_logo.jpg';
      case 'hehi':
        return 'https://www.hdfcergo.com/images/default-source/home/logo_hdfc.svg';
      case 'reliance':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/reliance-insurance-logo.png';
      case 'absli':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/assets/absli-logo.png';
      case 'absli-vv':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/assets/absli-logo.png';
      case 'kotak-vfmr':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/manipalcigna+health+insurance.jpeg';
      case 'kotak-vg':
        return 'https://visit-public.s3.ap-south-1.amazonaws.com/mer/manipalcigna+health+insurance.jpeg';
      default:
        return null;
    }
  };

  const onFinishAttempted = (values) => {
    //editing row is application_no
    let updatedDataSource = [...attemptedDataSource];
    let update_array = [];
    let new_obj = new Object();

    for (let index = 0; index < updatedDataSource.length; index++) {
      if (Object.values(updatedDataSource[index]).includes(editingRow)) {
        Object.keys(updatedDataSource[index]).map((key) => {
          if (updatedDataSource[index][key] != values[key] && updatedDataSource[index][key] != undefined && values[key] != undefined) {
            delete updatedDataSource[index][key];
            updatedDataSource[index][key] = values[key];
            new_obj[key] = values[key];
          }
        })

      }

    }
    update_array.push(new_obj);
    console.log('up', update_array, 'an', editingRow);



    alert('updated');

    updateAttemptedSheet(update_array, editingRow);
    setEditingRow(null);
  };




  const onFinishDiscrepency = (values) => {
    //editing row is application_no
    let updatedDataSource = [...discDataSource];
    let update_array = [];
    let new_obj = new Object();

    for (let index = 0; index < updatedDataSource.length; index++) {
      if (Object.values(updatedDataSource[index]).includes(editingRow)) {
        Object.keys(updatedDataSource[index]).map((key) => {
          if (updatedDataSource[index][key] != values[key] && updatedDataSource[index][key] != undefined && values[key] != undefined) {
            delete updatedDataSource[index][key];
            updatedDataSource[index][key] = values[key];
            new_obj[key] = values[key];
          }
        })

      }

    }
    update_array.push(new_obj);

    updateDiscrepencySheet(update_array, editingRow);


    alert('updated');

    setDiscDataSource(updatedDataSource);
    setEditingRow(null);
  };


  const onFinish = (values) => {
    //editing row is application_no
    let updatedDataSource = [...dataSource];
    let update_array = [];
    let new_obj = new Object();

    for (let index = 0; index < updatedDataSource.length; index++) {
      if (Object.values(updatedDataSource[index]).includes(editingRow)) {
        Object.keys(updatedDataSource[index]).map((key) => {
          if (updatedDataSource[index][key] != values[key] && updatedDataSource[index][key] != undefined && values[key] != undefined) {
            delete updatedDataSource[index][key];
            updatedDataSource[index][key] = values[key];
            new_obj[key] = values[key];
          }
        })
      }
    }
    update_array.push(new_obj);
    console.log('up', update_array, 'an', editingRow);
    updateCompleteSheet(new_obj, editingRow);


    alert('updated');

    setDataSource(updatedDataSource);
    setEditingRow(null);
  };



  return (
    <div>
      <OuterContainer>

        <LogoContainer src={visitLogo} />
        {getInsurerLogo && (
          <InsurerLogoContainer src={getInsurerLogo} />
        )}

      </OuterContainer>
      <div>




        <Tabs defaultActiveKey="1" centered >
          <Tabs.TabPane tab="Discrepency Cases" key="1" >
            <Form form={form} onFinish={onFinishDiscrepency}>
              <Table columns={Discrepencycolumns} dataSource={discDataSource} rowKey="application_no" pagination={{ position: ['topLeft', 'bottomRight'] }} scroll={{ x: "max-content" }} style={{ overflowY: 'scroll', maxHeight: '100vh' }} />
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Completed Cases" key="2">
            <Form form={form} onFinish={onFinish}   >
              <Table columns={columns} dataSource={dataSource} rowKey="application_no" pagination={{ position: ['topLeft', 'bottomRight'] }} scroll={{ x: "max-content" }} style={{ overflowY: 'scroll', maxHeight: '100vh' }} />
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attempted Cases" key="3">
            <Form form={form} onFinish={onFinishAttempted}>
              <Table columns={AttemptedCol} dataSource={attemptedDataSource} rowKey="application_no" pagination={{ position: ['topLeft', 'bottomRight'] }} scroll={{ x: "max-content" }} style={{ overflowY: 'scroll', maxHeight: '100vh' }} />
            </Form>
          </Tabs.TabPane>
        </Tabs>

      </div>
    </div>

  );
}

export default ViewSheets;